.select {
  transition: all 0.1s;
  border-radius: 5px;
}
.select:hover {
  background: #0066fa !important;
  color: #fff;
}

.os {
  color: #1d1b20 !important;
}

.osv {
  color: #49454f !important;
}

.sc {
  background-color: #ecedf9;
}

.surface {
  background-color: #fef7ff;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%)
    center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.hocactive {
  transition: all 0.3s;
  background-color: #e8def8 !important;
}

.plus {
  background-color: #0066fa !important;
}

input {
  background: #ecedf9 !important;
}
.discard {
  border: 1px solid #0066fa;
}

table {
  width: 100%;
  border-top: 1px solid #d8d9e5; /* Add border property */
  border-collapse: collapse;
}
th,
td {
  border-bottom: 1px solid #d8d9e5 !important;
  text-align: left;
  height: 55px;
}
th {
  background-color: transparent;
}

input[type="date"] {
  position: relative;
}

/* Style the date picker arrow */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none ;
}
